import React from 'react';
export class Page {
	private _pageModel: PageModel;

	public constructor(pageModel: PageModel) {
		this._pageModel = pageModel;
	}

	public get pageType(): string {
		return this._pageModel?.contentType;
	}

	public get footerContent(): FooterReference {
		return this._pageModel?.frontendConfiguration?.footerReference;
	}

	// public get headerContent(): HeaderReference {
	// 	return this._pageModel?.frontendConfiguration.headerReference;
	// }

	// public get ChatbotContent(): boolean {
	// 	return this._pageModel?.hideAlbottaScript;
	// }

	public get content(): MainArea {
		return this._pageModel?.mainArea;
	}

	public get theme(): string {
		return this._pageModel?.theme;
	}

	// public get url(): CanonicalUrl {
	// 	return this._pageModel?.canonicalUrl;
	// }
}

export const PageContext = React.createContext<Partial<Page>>(new Page(null));
