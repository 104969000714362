import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { ContainerLayout } from 'components/4-habitats';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);

	const { content } = pageContext ?? {};

	return (
		<>
			<Container
				desktopWidth='fullWidth'
				tabletWidth='fullWidth'
				mobileWidth='fullWidth'
			>
				<Container
					desktopWidth='1600'
					tabletWidth='1280'
					mobileWidth='744'
				>
					<Grid>
						<GridCell>
							<h1>`${'<HeaderComponent {...headerContent} />'}`</h1>
						</GridCell>
					</Grid>
				</Container>
			</Container>

			{content?.items?.map((component) => (
				<ContainerLayout
					component={component}
					key={component?.content?.contentId}
				/>
			))}

			<Container
				noPadding
				desktopWidth='fullWidth'
				tabletWidth='fullWidth'
				mobileWidth='fullWidth'
			>
				<h1>`${'<FooterComponent {...footerContent} />'}`</h1>
			</Container>
		</>
	);
};
