import { ContentPage } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export const pages = {
	FrontPage: ContentPage,
	ContentPage: ContentPage,
};

export const PageType: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);
	const pageType = pageContext.pageType;

	if (typeof pages[pageType] === 'undefined') {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					border: '20px red solid',
					fontSize: 'larger',
					fontWeight: 'bold',
					boxSizing: 'border-box',
				}}
			>{`Page type [${pageType}] is missing a React Component. Check /components/PageType.tsx`}</div>
		);
	}

	const DynamicPageTemplate = pages[pageType];

	return <DynamicPageTemplate />;
};
