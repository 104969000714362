export const Component: React.FC<any> = (props) => {
	const components = {
		BatgirlIFrameBlock: () => (
			<iframe
				src='https://codan.dk/codandkorch/CODANDK_ORCH/CreateQuote.aspx?PLProductText=Motor'
				height={2000}
				width={2000}
			/>
		),
	};

	const DynamicComponent = components[props.contentType as keyof typeof components];

	if (typeof DynamicComponent === 'undefined') {
		// TODO: Should it break in build?s
		console.error(`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`);
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					border: '20px red solid',
					fontSize: 'larger',
					fontWeight: 'bold',
					boxSizing: 'border-box',
				}}
			>{`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	return <DynamicComponent {...props} />;
};
